.afi-section {
  position: relative;
  min-width: 0;
  margin-top: 1.5rem;
  
  word-wrap: break-word; 
  padding: 0.5em;

  .afi-section-header {
    margin-bottom: 1rem;
    border-bottom: 1px solid #d8dbe0;

    display: flex;
    flex-direction: row;
    align-items: center;
  
    /*
    .afi-section-title {

    }
    */

    .afi-section-actions {
      margin-left: auto;
      justify-content: end;
    }
  }


}