// Import Core Ui styles
// (Bootstrap is included there)
@import "coreui/index";

// formiga-reactstrap styles
@import "../../../../node_modules/formiga-reactstrap/dist/styles.css";

// calendar
@import "afi_calendar";

// Custom styles
@import "afi_variables";
@import "afi_buttons";
@import "afi_breadcrumb";
@import "afi_card";
@import "afi_chat";
@import "afi_forms";
@import "afi_ibox";
@import "afi_inputs";
@import "afi_nice";
@import "afi_section";
@import "afi_steps";
@import "afi_tabs";
@import "afi_taxes";
@import "timeline";
@import "custom";

@import "app";

// Loading widget styles
@import "afi_loading";
