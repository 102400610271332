.afi-nice-list {
  padding: 1em 0.5em 0.5em 0.5em;

  &.bordered {
    border: 1px solid #0000001a;
  }
  &.transparent {
    
  }
  &:not(.transparent) {
    background-color: #fff;
  }

  .afi-nice-list-actions {
    width:100%;
    padding: 0em 1em;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    .afi-nice-list-actions-left {
      flex-grow: 1;
      width: 33%;
      display: flex;

      .afi-nice-list-clear-filter {
        user-select: none;
        &.active {
          cursor: pointer;
        }
      }
      .afi-nice-list-export {
        user-select: none;
        cursor: pointer;
      }

    }

    .afi-nice-list-actions-middle {
      flex-grow: 1;
      width: 33%;
      
      .afi-nice-list-search {
        text-align: center;
        input {
          border-style: solid;
          border-width: 1px;
          line-height: 2em;
        }
      }
    }

    .afi-nice-list-actions-right {
      flex-grow: 1;
      width: 33%;

      .afi-nice-list-pagination{
        text-align: right;

        .arrow,
        .page-num,
        .ellipsis,
        .select,
        .total {
          
          display: inline-block;
          min-width: 1.25em;
          font-size: 1em;
          padding-right: 0.5em;
          text-align: center;
        }
    
        .arrow,
        .page-num {
          cursor: pointer;
        } 
        
        .ellipsis,
        .total {
          opacity: 0.4;
        }
    
        .page-num,
        .arrow {
          opacity: 0.4;
          border-radius: 0.25em;
          &.active {
            opacity: 1;
          }
    
          &:hover:not(.active) {
            background-color: #ddd;
          }
        }
    
        .arrow {
          font-size: 1.5em;
        }
        
        .page-num {
          &.active {
            font-size: 1.5em;
          }
        }      
      }
    }


  }

  .afi-nice-list-table-wrap {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    .afi-nice-list-table {
      display         : table;
      border-collapse : separate;
      border-spacing  : 0.5em 0;
      width           : 100%;

      .afi-nice-list-header {
        display: table-row;

        @media screen and (max-width: $screen-sm) {
          display: none !important;
        }  

        .afi-nice-list-header-field {
          display      : table-cell;
          font-style   : italic;
          font-weight  : bold;
          color        : #969696;
          border-bottom: 1px solid #000000bf;
          padding      : 0.25em 0em;
          vertical-align: middle;


          .afi-nice-list-header-field-inner {
            display: flex;
            align-items: center;
            justify-content: left;
            user-select: none;

            .afi-nice-list-header-field-actions {
              white-space: nowrap;

              a {
                opacity: 0.3;
                font-size: 1.5em;
                cursor: pointer;
              }

              /*a:not(:first-child) {
                padding-left: 0.25em;
              }*/

              a.active {
                opacity: 1;
                color: #229e09;
              }
            }

            .afi-nice-list-header-field-label {
              cursor: pointer;

              &.filterable {
                text-decoration: underline;
              }
              &.active {
                color: #229e09;
              }
            }          
          }
          

          &.afi-nice-list-header-field-right {
            text-align: right;
            .afi-nice-list-header-field-inner {
              justify-content: right;
            }
          }

          &.afi-nice-list-header-field-center {
            text-align: center;
            .afi-nice-list-header-field-inner {
              justify-content: center;
            }
          }
        }
      }

      
      // Show border bottom except on last row
      .afi-nice-list-row:not(:last-child) {
        .afi-nice-list-item {
          border-bottom  : 1px solid #00000020;
        }
      }

      .afi-nice-list-row {
        display: table-row;

        @media screen and (max-width: $screen-sm) {
          display: block !important;
          border : 1px solid #00000020;
          margin-bottom: 1em;
        }    

        .afi-nice-list-item {
          display        : table-cell;

          //border-bottom  : 1px solid #00000020;
          padding        : 0.25em 0em;
          vertical-align : middle;
          min-height     : 35px;

          @media screen and (max-width: $screen-sm) {
            display: block /*!important*/;
            position: relative;
            padding : 1em;
            min-height : 2em;
          }           
        }

        .afi-nice-list-item.afi-nice-list-item-main {
          @media screen and (max-width: $screen-sm) {
            text-align: center;
          }
        }


        .afi-nice-list-item:not(.afi-nice-list-item-main):not(.afi-nice-list-item-right)::after {
          @media screen and (max-width: $screen-sm) {
            content: attr(data-name);
            color: #ddd9d9;
            font-size : 0.9em;
            font-style: italic;
            position: absolute;
            top: 0.15em;
            right: 1em;
          }
        }

        .afi-nice-list-item.afi-nice-list-item-empty {
          @media screen and (max-width: $screen-sm) {
            display: none;
          }
        }    

        .afi-nice-list-item.afi-nice-list-item-textual {
          color     : gray;
          font-style: italic;
          font-size : 0.9em;
        }

        .afi-nice-list-item.afi-nice-list-item-right {
          text-align: right;
          white-space: nowrap;
        }


        .afi-nice-list-item.afi-nice-list-item-price {
          text-align: right;
          color: green;
        }

        .afi-nice-list-item.afi-nice-list-item-cut_200 {
          .afi-nice-list-item-inner {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }
}


.afi-nice-list-filter-modal {
  max-width: 250px;

  .modal-header {
    h5.modal-title {
      width: 100%;
    }
    button.close {
      padding: 0;
      margin: unset;
      position: absolute;
      right: 1em;
      top: 1em;
    }
  }
  
  .afi-nice-list-filter-search  {
    margin-top: 1em;
    margin-bottom: -0.5em;

    .input-group-text {
      transform: rotate(180deg);
    }
  }

  .afi-nice-list-filter-list {
    max-height: 200px;
    overflow-y: auto;
    .afi-nice-list-filter-val {
      cursor: pointer;
      padding: 0.25em 0;
      user-select: none;
      
      &.active {
        opacity: 1;
      }
      &.inactive {
        opacity: 0.8;
      }
    }
  }

  .modal-footer {
    display: block;

    .afi-button-pair {
      text-align: center;
      margin-top: 1em;
    }
  }
}


.afi-process-resume {

  &.clickable:hover {
    cursor: pointer;
  background: rgb(238,238,238); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(204,204,204,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
  }

  &.no-processes {
    i {
      color: gray;
    }
  }
  .afi-process-title {
    text-align: right;
    font-size: 0.7em;
    color: "gray";
  }
  .afi-process-info {
    //display: flex;
    align-items: center;

    /*> div {
      flex: auto;
    }*/
  
    .bars {
      width: 100%;
      
    }
    .bars-label  {
      text-align: center;
    }
  }

  &:not(.no-processes) {
    position: relative;

    .afi-process-info:after {
      content: attr(data-title);
      position: absolute;
      top: 0.1em;
      right: 0;
      color: gray;
      font-size: 0.7em;
    }
  }  
}