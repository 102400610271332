/* VARIABLES */
.calendar {
  box-sizing: border-box;
  $main-color: #1a8fff;
  $text-color: #777;
  $text-color-light: #ccc;
  $border-color: #eee;
  $bg-color: #f9f9f9;
  $neutral-color: #fff;  


  display: block;
  position: relative;
  width: 100%;
  background: $neutral-color;
  border: 1px solid $border-color;
  margin-top: -2em;


  /* GRID */
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    &.row-middle {
      align-items: center;
    }

    .col {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;


      &.col-start {
        justify-content: flex-start;
        text-align: left;
      }
      
      &.col-center {
        justify-content: center;
        text-align: center;
      }
      
      &.col-end {
        justify-content: flex-end;
        text-align: right;
      }
    }
  }


  /* Header */
  .header {
    /*text-transform: uppercase;*/
    font-weight: 700;
    font-size: 115%;
    padding: 0.5em 0;
    border-bottom: 1px solid $border-color;
  
  
    .icon {
      cursor: pointer;
      transition: .15s ease-out;
    
    
      &:hover svg {
        transform: scale(1.75);
        transition: .25s ease-out;
        color: $main-color;
      }
      
      &.icon-left {
        margin-left: 1em;
      }
      
      &.icon-right {
        margin-right: 1em;
      }
    }
  }

  /* Days */
  .days {
    text-transform: uppercase;
    font-weight: 400;
    color: $text-color;
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid $border-color;
  }

  /* Body */
  .body {
    .row {
      border-bottom: 1px solid $border-color;
    
      & :last-child {
        border-bottom: none;
      }

      .col {
        flex-grow: 0;
        flex-basis: calc(100%/7);
        width: calc(100%/7);
        margin: 0;
        padding: 0;
      }

      .cell {
        position: relative;
        height: 12.75em;
        border-right: 1px solid $border-color;
        overflow: hidden;
        cursor: pointer;
        background: $neutral-color;
        /*transition: 0.25s ease-out;*/


        &.disabled {
          color: $text-color-light;
          //pointer-events: none;
          opacity: 0.7;
        }

        &:hover {
          background: $bg-color;
          transition: 0.5s ease-out;
        }

        &:last-child {
          border-right: none;
        }


        /* title number */
        &:before {
          content: attr(data-number);

          position: absolute;
          font-size: 82.5%;
          line-height: 1;
          top: .25em;
          right: .25em;
          font-weight: 700;

          background: $neutral-color;
          z-index: 3;
          border-radius: 1.5em;
          padding: 0.25em; 
        }
        &:hover:before {
          background: $bg-color;
        }

        /* background number with selection animation */
        &:after {
          content: attr(data-number);

          position: absolute;
          /*top: -0.07em;*/
          top: 0;
          left: 0;
          height: 100%;
          width: calc(100% - 5px);
          background-size: 100% 100%;
          font-size: 8em;
          letter-spacing: -.07em;          
          font-weight: 700;
          line-height: 1;
          text-align: right;

          color: $main-color;
          opacity: 0;
          transition: .25s ease-out;
        }
        
        &.selected:after,
        &:hover:after {
          border-left: 10px solid transparent;
          border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
          border-image-slice: 1;

          opacity: 0.1;
          transition: .5s ease-in;
        }

        /* events */
        .cell_content {
          padding: 1.5em 0;


        }
      }
    }
  }
}

.event {
  position: relative;
  z-index: 2;
  margin-bottom: 0.2em;
  padding: 0.25em 1em;
  user-select: none;
  overflow: hidden;

  &.empty {
    /* Equalify borders height */
    padding-top: calc(0.25em + 1px);
    padding-bottom: calc(0.25em + 1px);
  }

  &:not(.empty) {
    cursor: pointer;
    color: #663b3b;
    background-color: #9b9bf1;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    border: 1px solid #fafafa;
    box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);  

    //&:hover,
    &.hover {
      background-color: #5858f7;
      transition: 0.5s ease-out;
      border-color: #9d9d9d;
    }

    &.starts {
      border-top-left-radius: 1.5em;
      border-bottom-left-radius: 1.5em;
      margin-left: 0.75em;
    }

    &.ends {
      border-top-right-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
      margin-right: 0.75em;
    }     
  } 
  
  &.ellipsis {
    cursor: pointer;
    color: #5858f7;
    padding-top: 0;
    padding-bottom: 0;
    
    &:hover {
      color: #1d1daa;
    }
  }            
}

.calendar_popup {
  padding: 1em 2em;

  .row.mbottom {
    margin-bottom: 1em;
  }

  .event {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #fafafa;
    box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);    
  }

  /*.event:not(.starts) .event-content{
    z-index: -10;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    bottom: 0;
    top: calc(50% - 5px);
    left:0;
    box-sizing: border-box;
    
    border: 5px solid #fff;
    border-color: transparent transparent #fff #fff;
    
    transform-origin: 0 0;
    transform: rotate(45deg);
    
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);   
  }*/
  /*.event:not(.ends) {
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent; 
    border-bottom: 20px solid transparent; 
    border-left: 20px solid green;
    position:absolute;
    right:-20px;    
  }*/

}