input.floating_input {
  text-align: right;
  max-width: 75px;
}

.floating_sum_cnt {
  display: flex;
  overflow: hidden;
  white-space: nowrap;  

  .floating_sum_box {
    align-self: center;  
    flex: 0 1  auto;
    width: auto;

    &.positive::before,
    &.negative::before {
      content: "+";
      left: 1em;
      position: relative;
      color: #a4a4a4;
      font-weight: bold;      
    }

    &.positive::before {
      content: "+";
    }
    &.negative::before {
      content: "-";
    }
    &.negative::before,
    &.negative input {
      color: #e77777;
    }

    input.floating_input {
      text-align: right;
      max-width: 75px;
    
      &.dirt {
        border-color: #7d45c5;
        border-width: 1px;
        padding: 0.4em 0.6em;
        box-shadow: 0 0 0 0.2rem #a87fcc40;      
      }
    
    }


  }

  .floating_sum_result {
    align-self: center;  

    color: #a4a4a4;
    vertical-align: middle;
    padding-left: 1em;
  }  
}