
.formiga-reactstrap {
  //padding: 0 2em;


  .form-group label {
    color: #524f4f;
    font-style: italic;
    font-weight: bold;
    //padding-left: 4em;
    font-size: 1.1em;
  }

  .formiga-reactstrap-buttons {
    margin-top: 3em;
    text-align: center;

    .btn:not(:first-child) {
      margin-left: 1em;
    }

    .btn {
      svg {
        margin-right: 0.5em;
      }
    }
  }  

  .formiga-reactstrap-feedback {
    margin-top: 0.5em;
    color: #c75757;
    font-style: italic;
    padding-left: 4em;
  }

  .custom-switch {
    position: relative;
    left: 1em;
    top: 0.25em;
    margin-bottom: 0;
  }
}




