/* https://codepen.io/simeydotme/pen/JmKDi */

$afi-tab-color: #16c3ff80;
$afi-tab-border: #00000033;

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.afi-tabs > ul {
  
  text-align: center; 
  font-weight: 500;
  /*margin: 2em 0 0; */
  /*padding: 0; */
  position: relative;
  padding-bottom: 1px;
  border-bottom: 1px solid $afi-tab-border; 
  z-index: 1;
  
  > li {
    cursor: pointer;
    display: inline-block; 
    padding: 0.6em 0; 
    position: relative; 
    margin: 0 0 0 -4px;

    &.two {
      width: 50%; 
    }
    &.three {
      width: 33%; 
    }
    &.four {
      width: 25%; 
    }
    &.five {
      width: 20%; 
    }
    &.six {
      width: 25%; 
    }

    .afi-tabs-tab-title {
      &:focus { 
        outline: none; 
      }
    }


    &.afi-tabs-disabled {
      .afi-tabs-tab-title {
       opacity: 0.2;
     }
    }

    &.afi-tabs-light {
      
      .afi-tabs-tab-title {
        color: #f77e7e;
     }
    }    

    &:not(.afi-tabs-disabled) {
      
      &:before,
      &:after { 
        opacity: 0; 
        transition: 0.3s ease; 
        background-color: #fafafa; //#e4e5e6;
      }
      
      &.afi-tabs-active:before, &.afi-tabs-active:after,
      &:hover:before, &:hover:after,
      &:focus:before, &:focus:after { 
        opacity: 1; 
      }
      
      // this is the shadow under the text/mask
      &:before,
      &.afi-tabs-active:hover:before,
      &.afi-tabs-active:focus:before {
        content: "";
        position: absolute;
        z-index: -1;
        box-shadow: 0 2px 0px $afi-tab-color;
        top: 50%; 
        bottom: 0px; 
        left: 5px; 
        right: 5px;
      }
      
      // this is the "diamond" mask that creates the
      // nice indent effect.
      &:after,
      &.afi-tabs-active:hover:after,
      &.afi-tabs-active:focus:after {
        content: "";
        position: absolute;
        width: 12px; 
        height: 12px;
        left: 50%; 
        bottom: -6px; 
        margin-left: -6px;
        transform: rotate(45deg);
        box-shadow: 
          inset 3px 3px 3px $afi-tab-color, 
          inset 1px 1px 1px opacify($afi-tab-border,0.1);
      }

      &:hover:before,
      &:focus:before{
        box-shadow: 0 2px 0px $afi-tab-border;
      }

      &:hover:after,
      &:focus:after{
        box-shadow: 
          inset 3px 3px 3px $afi-tab-border, 
          inset 1px 1px 1px opacify($afi-tab-border,0.1);
      }
      
      &:focus span {
        text-decoration: underline;  
      }
      
      &:focus { 
        outline: none; 
      }
    }
  }
}

.afi-tabs-panels {
  /*padding: 2em;*/

  .afi-tabs-panel {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;    
  }
}