
/** BEGIN CSS **/
@keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-moz-keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-webkit-keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-o-keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-moz-keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-webkit-keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-o-keyframes afi-loading-rotate {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@keyframes afi-loading-text-opacity {
    0%  {opacity: 0}
    20% {opacity: 0}
    50% {opacity: 1}
    100%{opacity: 0}
}

@-moz-keyframes afi-loading-text-opacity {
    0%  {opacity: 0}
    20% {opacity: 0}
    50% {opacity: 1}
    100%{opacity: 0}
}

@-webkit-keyframes afi-loading-text-opacity {
    0%  {opacity: 0}
    20% {opacity: 0}
    50% {opacity: 1}
    100%{opacity: 0}
}

@-o-keyframes afi-loading-text-opacity {
    0%  {opacity: 0}
    20% {opacity: 0}
    50% {opacity: 1}
    100%{opacity: 0}
}
.afi-loading-container,
.afi-loading {
    height: 7em;
    position: relative;
    width: 7em;
    border-radius: 100%;
}


.afi-loading-container { margin: 2em auto }

.afi-loading {
    border: 2px solid transparent;
    /*border-color: transparent #fff transparent #FFF;*/
    border-color: transparent #7867a5 transparent #7867a5;
    -moz-animation: afi-loading-rotate 1.5s linear 0s infinite normal;
    -moz-transform-origin: 50% 50%;
    -o-animation: afi-loading-rotate 1.5s linear 0s infinite normal;
    -o-transform-origin: 50% 50%;
    -webkit-animation: afi-loading-rotate 1.5s linear 0s infinite normal;
    -webkit-transform-origin: 50% 50%;
    animation: afi-loading-rotate 1.5s linear 0s infinite normal;
    transform-origin: 50% 50%;
}

.afi-loading-container:hover .afi-loading {
    border-color: transparent #6e64cf transparent #6e64cf;
}
.afi-loading-container:hover .afi-loading,
.afi-loading-container .afi-loading {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

#afi-loading-text {
    -moz-animation: afi-loading-text-opacity 2s linear 0s infinite normal;
    -o-animation: afi-loading-text-opacity 2s linear 0s infinite normal;
    -webkit-animation: afi-loading-text-opacity 2s linear 0s infinite normal;
    animation: afi-loading-text-opacity 2s linear 0s infinite normal;
    color: #3c3939;
    font-family: "Helvetica Neue, "Helvetica", ""arial";
    font-size: 1em;
    font-weight: bold;
    margin-top: 2.5em;
    margin-left: 4px;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    /*width: 5em;*/
}