.ned-table  {
  display: table;
  width: 100%;
  
  .table-row {

    display: table-row;
    

    border-bottom: 2px solid #e0e0e0;
    border-collapse: collapse;
    padding: 5px 15px;
    align-items: center;
  

    &.header {
      font-weight: bold;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    div {
      display: table-cell;
      padding-right: 10px;
      text-align: left;
      .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    div.actions {
      width: 50px;
    }
  }
}







.status_arrow {
  cursor: pointer;
  opacity: 0.7;
}

.status_arrow button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 0px 0px;
  font-size: 0.8em;
}

.status_arrow button svg,
.status_arrow button i {
margin-right: 0;
}



.status_arrow:hover {
opacity: 1;
}

.status_arrow.status_arrow_left button {
background-color: #d6b7b3 !important;
}
.status_arrow.status_arrow_left:hover button {
background-color: #dd4b39 !important;
}

.status_arrow.status_arrow_right button {
background-color: #a8c1b6 !important;
}
.status_arrow.status_arrow_right:hover button {
background-color: #00a65a !important;
}



.proc-docu-actions {
  text-align: right;
}
.proc-docu-list {
  margin-top: 1em;
}


.afi-calendar-filter {
  text-align:right;

  .formiga-reactstrap-select-search {
    .mb-3 {
      margin-bottom: unset !important;
    }
    .formiga-reactstrap-select-search-list {
      left: 0;
      width: calc(100% - 2.6rem);
    }
  }
}



.afi-nice-list {
  .afi-nice-list-item {
    .formiga-reactstrap-form-group {
      &.mb-3 {
        margin-bottom: unset !important;
      }
    }
  }
}