:root {
  // DANGER

  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    /*You probably don't mean to use the color value white in interpolation here.
      It may end up represented as white, which will likely produce invalid CSS.
      Always quote color names when using them as strings or map keys (for example, "white").
      If you really want to use the color value here, use '"" + $color'.*/
    /*--#{$color}: #{$value};*/
    --#{"" + $color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{"" + $color}: #{$value};
  }

  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};
}
