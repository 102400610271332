
.afi_breadcrumb {
  @media screen and (max-width: $screen-sm) {
    display: none !important;
  }  

  ol.breadcrumb {
    margin-bottom: 0 !important;
    border-bottom: none !important;
    background-color: unset !important;
  }

  .breadcrumb-item.active {
    font-weight: bold;
  }
}
