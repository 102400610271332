// Adapt bootstrap
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;



$color-level1: #4f7757;
$color-level2: #6e6c6c; 
$color-level3: #6e6c6c; 
