.ibox {
  position: relative;
  /*display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;*/
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  
  background-clip: border-box;
  
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-radius: 0.25rem;
  border-color: #d8dbe0;

  padding: 0.5em;

  &.ibox-height {
    height: 100%;
  }



  &:not(.ibox-narrow) .ibox-content {
    padding: 3em;
  }

  .ibox-watermark {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.1em;
    
    line-height: 1em;
    color: #bcbcea;
    user-select: none;
    opacity: 0.3;
  }

  &:not(.ibox-narrow) .ibox-watermark {
    font-size: 4em;
  }



  .ibox-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    /*
    @media screen and (max-width: $screen-sm) {
      flex-direction: column;
    }
    */

    .ibox-title {
      flex: auto;
    }
    

    .ibox-actions {
      /*position: absolute;
      right: 0;
      top: 0;*/
      //padding: 0.5em;
      //text-align: right;
      margin-left: auto;
      justify-content: end;
    }
  
  }


  .ibox-content {

    .ibox-row {
      //margin-left: auto;
      width: 100%;  
      margin-bottom: 1em;

      
      
      

      > div {
        display: flex;
        flex-direction: row;
        //justify-content: end;
        align-items: center;
        width: 100%;  

        @media screen and (max-width: $screen-sm) {
          flex-direction: column;

          > * {
            margin-bottom: 0.5em;
          }
        }        
      }

      .ibox-iconed-text {
        margin-left: 2em;
        
        svg {
          opacity: 0.2;
        }

        > div {
          display: inline-block;
          margin-left: 0.5em;
          color: #3d3d3d;
        }
      }
    }

    .ibox-row.ibox-center {
      //text-align: center;
      margin-left: auto;
      margin-right: auto;
      > div {
        justify-content: center;
      }
    }

    .ibox-row.ibox-right {
      //text-align: right;
      margin-left: auto;
      > div {
        justify-content: end;
      }
    }
  }

  &.ibox-empty {
    border: 2px dashed #b6b6b6;

    .ibox-content {
      .ibox-row {
        color: #969494;
        font-style: italic;
      }
    }
  }
}