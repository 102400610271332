

.timeline {
  &.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;    
  }

  .item {
    display: flex;
    
    .image {
      padding: 1em 2em 1em 0;
      
      > div {
        position: relative;
        text-align: center;
        font-size: 0.8em;
        width: 50px;
  
        border-radius: 50%;
        height: 5em;
        border: 0.35em solid $gray-200;
        background: $body-bg;

        &::after {
          content: '';
          width: 110%;
          height: 0;
          border-bottom: 1px solid $gray-200;
          position: absolute;
          top: 2.75em;
          left: 2.5em;
          z-index: -1;
        }
      }
      svg {
        top: 0.5em;
        position: relative;
      }
      
      span {
        display: block;
        clear: both;
        padding: 0.25em 0;
        margin: 1.5em 0;
        background: $body-bg;
      }
    }
    
    .details {
      position: relative;
      flex-grow: 1;
  
      div.details_content {
        border: 1px solid $gray-200;
        border-radius: 0.5em;
        padding: 1em;
        margin: 1em 0;
        display: flex;
        align-items: stretch;

        .detail {

          align-self: baseline;

          &:not(:first-child) {
            margin-left: 1em;
          }

          &.left .timeline_collapse {
            cursor: pointer;
            left: 0.45em;
            position: relative;
          }
          
          &.title {
            color: $color-level1;
            font-size: 1.4em;
            letter-spacing: 0.1em;
          }

          &.info {
            flex: 1 1 auto;
          }

          &.right {
            flex: 1 1 auto;
            text-align: right;
          }
        }

      }
  
      &::before {
        content: '';
        width: 0;
        height: 100%;
        border-left: 1px solid $gray-200;
        position: absolute;
        top: -1em;
        left: -4.35em;
        z-index: -1;
      }


    }
    

    &.mini {
      .image {
        padding: 1em 3em 1em 1.01em;
        
        > div {
          width: 3em;
          border-radius: 50%;
          height: 3em;

          &::after {
            width: 160%;
            top: 1.25em;
            left: 2.75em;
          }          
        }
        svg {
          top: 0;
        }
        span {
          padding: 0 0;
          margin: 0.5em 0;
        }        
      }


      .details {
        div.details_content {
          border-radius: 0.5em;
          padding: 0.5em;
        }     
      }



    }

    &.last .details::before {
      height: 50%;
    }    
  }


}