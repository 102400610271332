.main .container-fluid {
  padding: 15px;
}



// Temp fix for reactstrap
.app-header {
  .navbar-nav {
    .dropdown-menu-right {
      right: auto;
    }
  }
}


.sidebar .nav-link.active {
  color: #20a8d8 !important;
  background-color: unset;
  text-decoration: underline;
}


.sidebar .nav .nav-item.nav-item-l2 > .nav-link {
  padding-left: 2em;
}
.sidebar .nav .nav-item.nav-item-l3 > .nav-link {
  padding-left: 3em;
}

/*
.input-group-addon,
.input-group-btn {
  min-width: 40px;
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}

.input-group-addon {
  padding: 0.15rem 0.75rem;
}
*/

.fa-1_5x {
    font-size: 1.5em;
}

.svg-inline--fa {
  margin-right: 5px;
}

.svg-inline--fa.fa-no-rmargin {
  margin-right: 0;
}

/*
 + Fix custom-select
 */

.custom-select,
.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
}
