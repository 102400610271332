
.afi_menu_card {
  text-decoration: none;
  color: inherit;
}

.afi_menu_card:hover {
  text-decoration: none;
  color: blue;
}

.afi_menu_card .card {
  text-align: center;
}

.afi_menu_card .alert {

  display      : block;
  float        : left;
  height       : 90px;
  width        : 100%;
  text-align   : center;
  font-size    : 45px;
  line-height  : 90px;
  margin-bottom: 0px;
  padding      : 0px;
}

.afi_menu_card .alert svg {
  width: auto !important;
  height: 60px !important;
  vertical-align: middle;
}

.afi_menu_card .card-title {
  font-weight: bold;
}


/*

*/

.card {
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  box-shadow: 0 0.25rem 0.75rem #0000001a;
}

.card-header {
  text-overflow: ellipsis;
  width: calc(100% - 1px); 
}

.card-header h1,
.card-title {
  font-weight: bold;
  font-size:1.5em;
  display:inline;
  color: #6e6c6c;
}

.card-body {
  overflow-x: auto;
}

.card-body .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}


.card-actions {
  float: right;
  text-align: center;
  background: transparent;
  margin-right: 1em;

  @media screen and (max-width: $screen-sm) {
    float: none !important;
  }

  .card-actions-cont {
   /* display       : flex;
    vertical-align: middle;*/
    text-align: right;

    .card-actions-group {
      display       : inline-block;
      vertical-align: middle;
      @media screen and (max-width: $screen-sm) {
        display       : block;
      }      
      

      .card-actions-filter {
        display       : inline;
        vertical-align: middle;
        text-align:   right;



        .card-actions-filter-input {
          display       : inline-block;
          vertical-align: middle; 
          /*min-width     : 200px;*/
          margin-right  : 1em;

          input.form-control {
            min-width     : 200px;
          }

          @media screen and (max-width: $screen-sm) {
            display       : unset !important;
            min-width     : unset !important;
            margin-right  : 0 !important;
            
            .form-group {
              width: 100% !important;
              margin        : 0.25em 0 !important;

            }
          }          
        }
      }

      .afi-action-button {
        @media screen and (max-width: $screen-sm) {
          margin-top : 0.25em !important;
        }           
      }
    }


  }
}

