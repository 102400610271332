
.afi-action {
  display: inline-block;
  user-select: none;
}

.afi-action > svg,
.afi-action > div {
  display: inline;
  vertical-align: middle;
}

.afi-action > div {
  margin-left: 7px;
}

.afi-action a {
  text-decoration: none !important;
  text-transform: capitalize;
  color: #4d88ff !important;
  font-size: bold !important;
  padding: 4px 0;
  cursor: pointer !important;
}

.afi-action a:hover {
  text-decoration: none !important;
  text-transform: capitalize;
  color: #004de6 !important;
  font-size: bold !important;
  font-style: italic !important;
  padding: 4px 0;
}



.afi-button-pair button:not(:first-child) {
  margin-left: 7px;
}

/*******************************
      afi-hyper
*******************************/

.afi-hyper {
  display         : inline-block;
  /*padding         : 0.5em;*/
  border-radius   : 0.2em;
  /*margin          : 0.5em;*/
  background-color: transparent;
  vertical-align  : middle; 
  overflow-x      : hidden;
  text-overflow   : ellipsis;
  white-space     : nowrap;
  max-width       : 250px;

  @media screen and (max-width: $screen-sm) {
    //max-width     : 300px; //calc(#{$screen-xs} - 50px) !important;
    overflow-x      : unset !important;
    text-overflow   : unset !important;
    white-space     : unset !important;
    max-width       : unset !important    
  }     
}

.afi-hyper a:hover {
  cursor: pointer;
background: rgb(238,238,238); /* Old browsers */
background: -moz-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(204,204,204,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
}

.afi-hyper a {
  text-decoration : none;
}

.afi-hyper svg {
  font-size: 1.5em;
}

.afi-hyper span {
  position: relative;
  /*top: -4px;*/
  vertical-align: middle;
  color           : black;
}


.afi-hyper a span {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #797979;
}

.afi-action-button-row {

  .afi-action-button:not(:last-child),
  > div:not(:last-child) {
    margin-right: 0.5em;
  }
}

.afi-action-button {
  display: inline-block;
  border: 0.5px solid lightgrey;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
  user-select: none;
  height: fit-content;



  svg {
    vertical-align: middle;
    
  }

  &.with-label svg {
    margin-right: 0.5em;
  }
  .label {
    vertical-align: middle;
  }

  &:hover:not(.disabled) {
    box-shadow: 0.2em 0.1em 0.1em #606060;
    background-color: rgb(240, 240, 240);
    cursor: pointer;
    text-decoration: none;
  }

  a {
    text-decoration: none;
    color: black;
    /*padding: 0.5em;*/
    /*top: 0.5em;*/
    /*position: relative;*/
   }

   &.only-icon a {
    text-align: center
  }

  &.disabled {
    cursor: none;
    pointer-events: none;
    svg {
      opacity: 50%;
    }
  }  
}


.afi-toggler-button {
  display: inline-block;
  border: 0.5px solid lightgrey;
  border-radius: 0.2em;
  padding: 0.1em;
}

.afi-toggler-button:hover {
  box-shadow: 0.2em 0.1em 0.1em #606060;
  background-color: rgb(240, 240, 240);
  cursor: pointer;
}

.afi-toggler-button a {
  text-decoration: none;
  color: black;
  margin: 0px 5px;
 }
