.taxes_table_cnt {
  //background-color: #f0f0e1;
  //padding: 1em 0;
  border: 1px solid #c2cfd6;
  margin-top: 2em;

  table.taxes_table {
    border-collapse: collapse;
    
    &.table-responsive {
      overflow-x: unset !important;
    }

    &.table-hover tbody tr:hover {
      background: #d4d4d4;
      cursor: pointer;
    }
    
    thead {
      tr:first-child {
        th {
          border-bottom: 0px;
          //padding-bottom: 0px;
        }
      }
      tr:not(:first-child):not(:last-child) {
        th {
          border-top: 0px;
          border-bottom: 0px;
          //padding-top: 0px;
          //padding-bottom: 0px;
        }
      }
      tr:last-child {
        th {
          border-top: 0px;
          //padding-top: 0px;
        }
      }
      

      tr {
        th {
          &.interests {
            cursor: pointer;

            &.not-ready {
              color: gray;
            }
          
            &.active {
              color: red;
            }  

            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &.afi_taxes_table {
    tbody {
      tr {
        &.afi_taxes_det {
          color: #504e4e;

          .form-group {
            margin-bottom: 0 ;
          }
        }

        &.afi_taxes_int {
          color: #838080;

         /* td {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            background-color: #ecf1f4;            
          }*/
        }
      }
    }
  }

  
  tr th,
  tr td {
    background-color: #f0f0e1;
    //padding: 0.25rem;

    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }    
    
    &:nth-child(odd) {
      background-color: #ebebe6;
    }
  }
}
