// Here you can add other styles
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.afi_show_input_group .afi_show_input_imode {
  display: none;
}
.afi_show_input_group .afi_show_input_smode {
  display: block;
}

.afi_show_input_group[data-show='true'] .afi_show_input_imode {
  display: block;
}
.afi_show_input_group[data-show='true'] .afi_show_input_smode {
  display: none;
}

.pull-right {
  float: right;
}

.main {
  padding: 2em;

  .container-fluid {
    padding: 0;
  }
}

h1 {
  font-weight: bold;
  font-size: 2em;
  color: $color-level1;      
  /*position: relative; 
  top: -0.5em;*/
}
h2 {
  font-weight: bold;
  font-size: 1.5em;
  color: $color-level2; 
  //margin-top: 2em;
}
h3 {
  font-weight: bold;
  font-size: 1.25em;
  color: $color-level3; 
  //margin-top: 1.25em;
}

.main {

  .page-header {
    //display: flex;
    padding-bottom: 2em;
  
    /*
    .page-left,
    .page-title,
    .page-actions {
      flex: auto;
    }*/
  
    .page-title {
      text-align: left;
    }
    .page-title.center {
      text-align: center;
    }
  
    .page-actions {
      text-align: right;
      white-space: nowrap;
    } 
  }

  .page-subtitle {
    display: flex;
    margin-bottom: 1em;

    div.page-subtitle-actions {
      flex: auto;
      text-align: right;
    }
  }

  .page-subtitle:not(:first-child) {
    margin-top: 2em;
  }
}



/*
.afi-divider-line {
  &:not(:first-child) {
    margin-top: 2em;
  }
  margin-bottom: 2em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .45);
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0.09em;
    min-width: 30vw;
  }
  
  &::before {
    background: linear-gradient(to right, rgba(240,240,240,0), $color-level1);
    margin-right: 4vh;
  }
  
  &::after {
    background: linear-gradient(to left, rgba(240,240,240,0), $color-level1);
    margin-left: 4vh;
  } 
}

*/